import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import App from "../../App.js";
import "./widgets.scss";

export default class AmountWidget extends Component {
  static StyleEnum = {
    GOOD: "widget-box-good",
    NEUTRAL: "widget-box-neutral",
    BAD: "widget-box-bad",
  };

  render() {
    return (
      <Grid
        xs={12}
        item
        style={{ marginRight: "10px" }}
        className={this.props.style}
      >
        <Box className="widget-header">{this.props.header}</Box>
        <Box className="widget-amount-primary">
          {this.props.approximateAmount && "≈"}
          {App.number_to_currency_string_static(
            this.props.amount,
            this.props.currency
          )}
        </Box>
      </Grid>
    );
  }
}
