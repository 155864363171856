import React from "react";

export default function CalculationResultDetailsNumberRow({
  description,
  value,
}) {
  return (
    <div className="details__flex details__flex--between">
      <p>{description}</p>
      <p className="number">{value}</p>
    </div>
  );
}
