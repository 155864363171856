import React from "react";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AlertForm from "../Alerts/AlertForm";
import AddAlertIcon from "@material-ui/icons/AddAlert";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import Hidden from "@material-ui/core/Hidden";
import { withStyles } from "@material-ui/core/styles";

export default function Navbar({ amount, setTickerCollapsed }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleAlertIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const StyledPopover = withStyles((theme) => ({
    root: {
      "& .MuiPopover-paper": {
        backgroundColor: "#373740",
      },
    },
  }))(Popover);

  return (
    <div>
      <Hidden smUp>
        <IconButton onClick={handleAlertIconClick} aria-label="New Price Alert">
          <AddAlertIcon />
        </IconButton>
        <IconButton aria-label="Markets">
          <ShowChartIcon
            onClick={() =>
              setTickerCollapsed((previousValue) => !previousValue)
            }
          />
        </IconButton>
      </Hidden>
      <Hidden xsDown>
        <Button
          aria-describedby={id}
          color="primary"
          size="small"
          disableRipple
          startIcon={<AddAlertIcon />}
          onClick={handleAlertIconClick}
          style={{ marginLeft: "16px", fontSize: "12px" }}
        >
          New Price Alert
        </Button>
        <Button
          aria-describedby={id}
          color="primary"
          size="small"
          disableRipple
          startIcon={<ShowChartIcon />}
          onClick={() => setTickerCollapsed((previousValue) => !previousValue)}
          style={{ marginLeft: "16px", fontSize: "12px" }}
        >
          Markets
        </Button>
      </Hidden>
      <StyledPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <AlertForm defaultAmount={amount} />
      </StyledPopover>
    </div>
  );
}
