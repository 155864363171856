import React, { useMemo } from "react";
import ReactEcharts from "echarts-for-react";
import { Box } from "@material-ui/core";

export default function BarChart({
  exchange_calculation_result,
  exchange_list,
}) {
  const getBottomThreeExchanges = (
    exchange_calculation_result,
    exchange_list
  ) => {
    const numberOfResults = 3;
    let resultCount = 0;
    let resultEntries = [];
    let resultExchangeList = [];

    if (exchange_calculation_result.length > 0 && exchange_list.length > 0) {
      let bestAmountLeft = exchange_calculation_result[0].fiat_amount_left;

      for (let i = exchange_calculation_result.length - 1; i > 0; i--) {
        let entry_exchange_id = exchange_calculation_result[i].exchange.id;
        let entry_exchange_amount =
          exchange_calculation_result[i].fiat_amount_left;

        if (
          entry_exchange_amount !== bestAmountLeft &&
          !resultExchangeList.some((value) => value === entry_exchange_id) &&
          resultCount < numberOfResults
        ) {
          resultExchangeList.push(entry_exchange_id);

          let entry_exchange_name =
            exchange_list.find((o) => o.id === entry_exchange_id) &&
            exchange_list.find((o) => o.id === entry_exchange_id).name;

          let buy_method = exchange_calculation_result[i].steps.find(
            (o) => o.fee_type.fee_type === "INBOUND"
          ).fee_type.transaction_type;

          resultEntries.push({
            exchange_name: entry_exchange_name + "\n" + buy_method,
            fee: (bestAmountLeft - entry_exchange_amount).toFixed(2),
          });
          resultCount++;
        }
      }
      return resultEntries;
    } else {
      return [
        { exchange_name: "", fee: 0 },
        { exchange_name: "", fee: 0 },
        { exchange_name: "", fee: 0 },
      ];
    }
  };

  const memoizedData = useMemo(
    () => getBottomThreeExchanges(exchange_calculation_result, exchange_list),
    [exchange_calculation_result, exchange_list]
  );

  const options = {
    grid: {},
    cursor: "normal",
    title: {
      text: "Losses for $10k CAD to BTC conversion ",
      subtext: "Includes fees and exchange rate losses. Calculated right now.",
      textStyle: {
        color: "rgba(255, 255, 255, 0.8)",
        fontWeight: 100,
      },
    },
    textStyle: {
      fontSize: 12,
      color: "rgba(255, 255, 255, 0.6)",
      fontWeight: 100,
      fontFamily: "Roboto Condensed",
    },

    xAxis: {
      type: "category",
      data: [
        memoizedData[0].exchange_name,
        memoizedData[1].exchange_name,
        memoizedData[2].exchange_name,
      ],
      axisLine: { onZero: false },
      splitLine: { show: false },
      splitArea: { show: false },
    },
    yAxis: {
      type: "value",
      inverse: true,
      axisLabel: {
        // eslint-disable-next-line no-template-curly-in-string
        formatter: "${value}",
        fontFamily: "Eczar",
        fontWeight: 100,
      },
    },

    series: [
      {
        silent: true,
        name: memoizedData[0].exchange_name,
        type: "bar",
        stack: "one",
        data: [memoizedData[0].fee, memoizedData[1].fee, memoizedData[2].fee],
        label: {
          show: true,
          // eslint-disable-next-line no-template-curly-in-string
          formatter: "${@data}",
          fontSize: 14,
          fontFamily: "Eczar",
          color: "rgba(0, 0, 0, 0.8)",
        },
        color: "rgba(180, 180, 180, 1)",
      },
    ],
  };

  return (
    <Box>
      {memoizedData[0].exchange_name !== "" && (
        // @ts-ignore
        <ReactEcharts option={options} />
      )}
    </Box>
  );
}
