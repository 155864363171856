import React from "react";
import Box from "@material-ui/core/Box";
import "./marketingHomePage.scss";
import MarketingHeaderSubtitle from "./MarketingHeaderSubtitle";

//TODO: mixing exchange rate
export default function MarketingHomePage({
  exchange_calculation_result,
  exchange_list,
}) {
  return (
    <Box>
      <MarketingHeaderSubtitle
        exchange_calculation_result={exchange_calculation_result}
        exchange_list={exchange_list}
      />
    </Box>
  );
}
