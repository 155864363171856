import React from "react";
import Box from "@material-ui/core/Box";
import BannerExchange from "./BannerExchange";
import "./ticker.scss";
import CurrencySummaryWidget from "./CurrencySummaryWidget";
import Collapse from "@material-ui/core/Collapse";
import Navbar from "../Navigation/Navbar.jsx";

export default function BannerContainer({
  exchange_rates,
  exchange_list,
  market_summary,
}) {
  const [collapsed, setTickerCollapsed] = React.useState(false);
  return (
    <div style={{ width: "100%" }}>
      <Collapse in={collapsed}>
        <Box
          className="banner-container"
          display="flex"
          flexDirection="row"
          flexWrap="nowrap"
        >
          {exchange_rates &&
            exchange_rates.map((exchange_rate) => (
              <BannerExchange
                key={
                  exchange_rate.exchange.id +
                  exchange_rate.symbol.base_currency +
                  "/" +
                  exchange_rate.symbol.quote_currency
                }
                buyAmount={exchange_rate.bid_price}
                sellAmount={exchange_rate.ask_price}
                exchange={exchange_list.find(
                  (o) => o.id === exchange_rate.exchange.id
                )}
                symbol={
                  exchange_rate.symbol.base_currency +
                  "/" +
                  exchange_rate.symbol.quote_currency
                }
              />
            ))}
        </Box>
      </Collapse>
      <Box
        display="flex"
        style={{
          paddingTop: "4px",
          borderBottom: "1px solid #045d56",
          position: "relative",
        }}
        justifyContent="space-between"
      >
        {market_summary &&
          market_summary.map(
            (summary) =>
              summary.average_market_price && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  style={{ width: "100%" }}
                >
                  <CurrencySummaryWidget
                    currency={summary.symbol.base_currency}
                    amount={summary.average_market_price}
                    day_change_percent={summary.day_change_percent}
                  />
                  <Navbar
                    amount={summary.average_market_price}
                    setTickerCollapsed={setTickerCollapsed}
                  />
                </Box>
              )
          )}
      </Box>
    </div>
  );
}
