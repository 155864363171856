import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import "./widgets.scss";
import AccessTimeIcon from "../Icon/AccessTimeIcon";

export default function UpdatedWidget({ fromTime, toTime }) {
  const refreshInterval = 60;
  const deltaTimeInitial = toTime - fromTime;

  const [seconds, setSeconds] = useState(deltaTimeInitial);
  const [show, setShow] = useState(true);

  const getCaptionFromDuration = () => {
    if (seconds < 300) {
      return "a few seconds ago";
    } else if (seconds <= 600) {
      return "5 mins ago";
    } else if (seconds <= 900) {
      return "10 mins ago";
    } else if (seconds <= 3600) {
      return "a few mins ago";
    } else if (seconds <= 7200) {
      return "an hour ago";
    }
    setShow(false);
    return "";
  };

  useEffect(() => {
    let myInterval = setInterval(() => {
      setSeconds(seconds + refreshInterval);
    }, refreshInterval * 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  if (fromTime && toTime && seconds && !isNaN(seconds) && show) {
    return (
      <div>
        <Hidden xsDown>
          <Box
            display="flex"
            justifyContent="flex-end"
            flexDirection="row"
            alignItems="center"
            className="widget-updated-container"
          >
            <AccessTimeIcon style={{ paddingRight: "4px", fontSize: "12px" }} />
            {"Updated "}
            {getCaptionFromDuration()}
          </Box>
        </Hidden>
        <Hidden smUp>
          <Box
            display="flex"
            justifyContent="flex-end"
            flexDirection="row"
            alignItems="center"
            className="widget-updated-container"
          >
            {"Updated "}
            {getCaptionFromDuration()}
          </Box>
        </Hidden>
      </div>
    );
  } else {
    return <span />;
  }
}
