import React, { Component } from "react";
import Box from "@material-ui/core/Box";
import FiberNewIcon from "@material-ui/icons/FiberNew";
import "./ticker.scss";

export default class BannerExchange extends Component {
  state = {
    buyAmountA: 0,
    buyAmountB: 0,
    sellAmountA: 0,
    sellAmountB: 0,
  };

  numberDiffSplitter(numberA, numberB) {
    let maxStringSize = 24;
    let normalizingCharacter = "Z";
    let stringA =
      "$" +
      Number(numberA).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    let stringB =
      "$" +
      Number(numberB).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    let numberALength = stringA.length;
    let numberBLength = stringB.length;
    let normalizedA = stringA.padStart(maxStringSize, normalizingCharacter);
    let normalizedB = stringB.padStart(maxStringSize, normalizingCharacter);
    let positionThatsDifferent = 0;

    for (let i = 0; i < maxStringSize; i++) {
      if (normalizedA[i] !== normalizedB[i]) {
        positionThatsDifferent = i;
        break;
      }
    }

    let resultA = [
      normalizedA.substring(
        maxStringSize - numberALength,
        positionThatsDifferent
      ),
      normalizedA.substring(positionThatsDifferent, maxStringSize),
    ];

    let resultB = [
      normalizedB.substring(
        maxStringSize - numberBLength,
        positionThatsDifferent
      ),
      normalizedB.substring(positionThatsDifferent, maxStringSize),
    ];

    return { buy: resultA, sell: resultB };
  }

  componentDidMount() {
    let result = this.numberDiffSplitter(
      this.props.buyAmount,
      this.props.sellAmount
    );
    this.setState({
      buyAmountA: result.buy[0],
      buyAmountB: result.buy[1],
      sellAmountA: result.sell[0],
      sellAmountB: result.sell[1],
    });
  }

  render() {
    return (
      <Box
        className="banner-item"
        display="flex"
        flexDirection="row"
        position="relative"
      >
        <Box
          className="banner-container-title"
          display="flex"
          flexDirection="column"
        >
          {this.props.exchange && this.props.exchange.is_new_indicator && (
            <Box className="banner-is-new-indicator">
              <FiberNewIcon />
            </Box>
          )}
          {this.props.exchange && this.props.exchange.name}
          <br />
          {this.props.symbol}
        </Box>
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={0}
          >
            <Box className="banner-side">Buy</Box>
            <Box className="banner-amount">{this.state.buyAmountA}</Box>
            <Box className="banner-amount-diff">{this.state.buyAmountB}</Box>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={0}
          >
            <Box className="banner-side">Sell</Box>
            <Box className="banner-amount">{this.state.sellAmountA}</Box>
            <Box className="banner-amount-diff">{this.state.sellAmountB}</Box>
          </Box>
        </Box>
      </Box>
    );
  }
}
