import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import "./marketingHomePage.scss";
import BarChart from "../Charts/BarChart";
import { Hidden } from "@material-ui/core";

export default function MarketingHeaderSubtitle({
  exchange_calculation_result,
  exchange_list,
}) {
  return (
    <Grid container className="headerContainer">
      <Hidden xsDown>
        <Grid item xs={7}>
          <Box className="header">Find the best exchange rates.</Box>
          <Box className="subtitle">
            Save money and uncover all hidden fees and inefficient rates.
          </Box>
        </Grid>
        <Grid item xs={5} className="barchart-box">
          <BarChart
            exchange_calculation_result={exchange_calculation_result}
            exchange_list={exchange_list}
          />
        </Grid>
      </Hidden>
      <Hidden smUp>
        <Grid item xs={12}>
          <Box className="header">Find the best exchange rates.</Box>
          <Box className="subtitle">
            Save money and uncover all hidden fees and inefficient rates.
          </Box>
        </Grid>
      </Hidden>
    </Grid>
  );
}
