import React from "react";
import Box from "@material-ui/core/Box";
import App from "../../App.js";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import "./ticker.scss";

export default function CurrencySummaryWidget({
  currency,
  amount,
  day_change_percent,
}) {
  return (
    <Box
      display="flex"
      alignItems="flex-end"
      className="market-summary-container"
    >
      <img
        src={process.env.PUBLIC_URL + "/currency/" + currency + ".png"}
        alt={currency}
        className="market-summary-icon"
      />
      <Box className="market-amount-primary">
        {amount && App.number_to_currency_string_static(amount, "CAD")}
      </Box>
      {day_change_percent != null && day_change_percent >= 0 && (
        <ArrowDropUpIcon
          style={{
            fill: "#1eb980",
            fontSize: "2.5em",
            marginLeft: "2px",
            marginRight: "-4px",
            marginBottom: "-4px",
          }}
        />
      )}
      {day_change_percent != null && day_change_percent < 0 && (
        <ArrowDropDown
          style={{
            fill: "#ff3522",
            fontSize: "2.5em",
            marginLeft: "2px",
            marginRight: "-4px",
            marginBottom: "-4px",
          }}
        />
      )}
      {day_change_percent != null && (
        <Box className="market-amount-secondary">
          {App.number_to_currency_string_static(day_change_percent, "%")}
        </Box>
      )}
    </Box>
  );
}
