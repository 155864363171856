import React, { Component } from "react";
import Logo from "./components/Logo/Logo";
import Footer from "./components/Footer/Footer";
import Form from "./components/Form/Form";
import axios from "axios";
import "./App.scss";
import BannerContainer from "./components/Ticker/BannerContainer";
import { Container, createMuiTheme, ThemeProvider } from "@material-ui/core";
import MarketingHomePage from "./components/MarketingHomePage/MarketingHomePage";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import ErrorFallback from "./components/Error/ErrorFallback";
import { sendAmplitudeData } from "./utilities/amplitude";

const cryptolens_url = process.env.REACT_APP_URL;
const pipeline_calculation_api = process.env.REACT_APP_PIPELINE_API;
const exchange_list_api = process.env.REACT_APP_EXCHANGE_LIST_API;
const environment_name = process.env.REACT_APP_ENVIRONMENT_NAME;
const exchange_rate_api = process.env.REACT_APP_EXCHANGE_RATE_API;
const market_summary_api = process.env.REACT_APP_MARKET_SUMMARY_API;

Sentry.init({
  dsn:
    "https://fd12dd54cf6c4371a4115ae9aa9874ba@o502889.ingest.sentry.io/5586323",
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
  environment: environment_name,
  tracesSampleRate: 0,
});

//TODO: API error management. what if it does not respond or returns 400, 500
export default class App extends Component {
  constructor() {
    super();
    this.scrollRef = React.createRef();
  }

  static initalCADAmount = 10000;
  static initalBTCAmount = 1;

  state = {
    exchange_calculation_result: [],
    exchange_rates: [],
    exchange_list: [],
    market_summary: [],
    init_exchange_calculation_result: [],
    is_first_calculation_result: true,
    // isLoading does not include isLoadingMarketSummary
    isLoading: true,
    isLoadingCalculation: true,
    isLoadingRates: true,
    isLoadingExchangeList: true,
    isLoadingMarketSummary: true,
    index: 0,
    modalIsOpen: false,
    symbol: "BTC_CAD",
    side: "BUY",
    lastApiAmount: App.initalCADAmount,
    amount: App.initalCADAmount,
    amountHeader: "BTC bought",
    lastApiSide: "BUY",
    errorLabel: [],
  };

  fetchPipelineCalculation() {
    this.setState({ isLoading: true, isLoadingCalculation: true });
    const pipeline_calculation_url =
      cryptolens_url +
      pipeline_calculation_api +
      "?amount=" +
      this.state.amount +
      "&symbol=" +
      this.state.symbol +
      "&side=" +
      this.state.side;
    axios
      .get(pipeline_calculation_url)
      .then((res) => {
        let sortedExchanges = [];
        if (res.data) {
          sortedExchanges = res.data.sort((a, b) => {
            if (Number(a.amount_left) === Number(b.amount_left)) {
              return a.id - b.id;
            } else {
              return Number(a.amount_left) < Number(b.amount_left) ? 1 : -1;
            }
          });
        }

        if (this.state.is_first_calculation_result) {
          this.setState({
            init_exchange_calculation_result: sortedExchanges,
            is_first_calculation_result: false,
          });
        }

        this.setState({
          exchange_calculation_result: sortedExchanges,
          index: 0,
          lastApiAmount: this.state.amount,
          isLoadingCalculation: false,
          isLoading:
            this.state.isLoadingExchangeList || this.state.isLoadingRates,
          amountHeader:
            this.state.side === "BUY" ? "BTC bought" : "CAD received",
          lastApiSide: this.state.side,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }
  fetchExchangeList() {
    this.setState({ isLoading: true, isLoadingExchangeList: true });
    axios
      .get(cryptolens_url + exchange_list_api)
      .then((res) => {
        this.setState({
          exchange_list: res.data,
          isLoadingExchangeList: false,
          isLoading:
            this.state.isLoadingCalculation || this.state.isLoadingRates,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  fetchExchangeRates() {
    this.setState({ isLoading: true, isLoadingRates: true });
    const exchange_rate_uri = cryptolens_url + exchange_rate_api;
    console.info("fetching exchange rates");
    axios
      .get(exchange_rate_uri)
      .then((res) => {
        this.setState({
          exchange_rates: res.data,
          isLoadingRates: false,
          isLoading:
            this.state.isLoadingCalculation || this.state.isLoadingExchangeList,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  fetchMarketSummary() {
    this.setState({ isLoadingMarketSummary: true });
    const exchange_rate_uri = cryptolens_url + market_summary_api;
    console.info("fetching market summary");
    axios
      .get(exchange_rate_uri)
      .then((res) => {
        this.setState({
          market_summary: res.data,
          isLoadingMarketSummary: false,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  componentDidMount() {
    this.fetchExchangeList();
    this.fetchExchangeRates();
    this.fetchPipelineCalculation();
    this.fetchMarketSummary();

    // updating the state amount which is used for effective rate calculation
    this.setState({ lastApiAmount: this.state.amount });

    sendAmplitudeData("APP MOUNTED");
  }

  setKey = (index) => {
    this.setState({
      index: index,
    });
  };

  handleChange = (event, newSide) => {
    if (newSide !== null) {
      let newAmount;
      if (newSide === "SELL") {
        newAmount = App.initalBTCAmount.toFixed(2);
      } else if (newSide === "BUY") {
        newAmount = App.initalCADAmount;
      }
      this.setState({
        side: newSide,
        amount: newAmount,
      });
    }
  };
  inputErrorLabel = (event) => {
    let amount = event.target.value;
    if (amount < 0 || amount === "-") {
      this.setState({
        error: true,
        errorLabel: "Enter a positive number",
      });
    } else if (amount > 999999) {
      this.setState({
        error: true,
        errorLabel: "Enter a number less than $1M",
      });
    } else if (amount === isNaN) {
      this.setState({
        error: true,
        errorLabel: "Enter a number",
      });
    } else if (
      amount == null ||
      amount === undefined ||
      amount === "" ||
      amount === []
    ) {
      this.setState({
        error: true,
        errorLabel: "Enter a number",
      });
    } else if (amount === "0") {
      this.setState({
        error: true,
        errorLabel: "Enter amount greater than 0",
      });
    } else {
      this.setState({
        error: false,
        errorLabel: [],
      });
    }
  };
  amountChange = (event) => {
    let val = event.target.value;
    let skipStateSetting = false;
    if (!skipStateSetting) {
      const amount = event.target.name;
      this.setState({
        [amount]: val,
      });
    }
  };

  onSubmit = (event) => {
    this.setState({ isLoading: true });
    this.scrollRef.current && this.scrollRef.current.scrollTo(0, 0);
    event.preventDefault();
    this.fetchPipelineCalculation();
    sendAmplitudeData("ON_SUBMIT", {
      amount: this.state.amount,
      symbol: this.state.symbol,
      side: this.state.side,
    });
  };

  openModal = (exchange_index) => {
    this.setKey(exchange_index);
    this.setState({
      modalIsOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    });
  };

  static number_to_currency_string_static = (amount, symbol = "CAD") => {
    if (symbol === "%") {
      return (
        Number(amount).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) + "%"
      );
    }
    if (symbol === "CAD") {
      return (
        "$" +
        Number(amount).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      );
    } else {
      let number_amount = Number(amount);
      if (number_amount > 1) {
        return (
          number_amount.toLocaleString("en-US", {
            minimumFractionDigits: 4,
            maximumFractionDigits: 4,
          }) +
          " " +
          symbol
        );
      } else {
        return (
          (number_amount * 1000).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) +
          " m" +
          symbol
        );
      }
    }
  };

  //TODO: refactor this out. use the static function instead
  number_to_currency_string = (amount, symbol = "CAD") => {
    return App.number_to_currency_string_static(amount, symbol);
  };

  theme = createMuiTheme({
    typography: {
      fontFamily: ["Roboto Condensed", "Eczar"].join(","),
    },
    palette: {
      type: "dark",
      primary: {
        main: "#045d56",
      },
      secondary: {
        main: "#72DEFF",
      },
    },
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: "12px",
          position: "relative",
          top: "16px",
        },
      },
      MuiTableCell: {
        root: {
          padding: "16px",
          color: "#FFFFFF",
          opacity: 0.6,
          borderBottomColor: "rgb(255,255,255, 0.12)",
        },
        head: {
          color: "#ffffff",
          fontSize: 14,
          borderBottomColor: "#045d56",
        },
        body: {
          fontSize: 14,
          color: "#ffffff",
        },
      },
    },
  });

  render() {
    return (
      <Sentry.ErrorBoundary
        fallback={({ error, componentStack, resetError }) => (
          <ErrorFallback
            error={error}
            componentStack={componentStack}
            resetError={resetError}
          />
        )}
      >
        <ThemeProvider theme={this.theme}>
          <Container maxWidth="lg">
            <BannerContainer
              exchange_rates={this.state.exchange_rates}
              exchange_list={this.state.exchange_list}
              market_summary={this.state.market_summary}
            />
            <Logo />
            <MarketingHomePage
              exchange_calculation_result={
                this.state.init_exchange_calculation_result
              }
              exchange_list={this.state.exchange_list}
            />
            <Form
              scrollRef={this.scrollRef}
              exchange_calculation_result={
                this.state.exchange_calculation_result
              }
              index={this.state.index}
              modalIsOpen={this.state.modalIsOpen}
              setKey={this.setKey}
              onSubmit={this.onSubmit}
              openModal={this.openModal}
              closeModal={this.closeModal}
              number_to_currency_string={this.number_to_currency_string}
              exchange_list={this.state.exchange_list}
              symbol={this.state.symbol}
              handleChange={this.handleChange}
              side={this.state.side}
              amountChange={this.amountChange}
              amount={this.state.amount}
              lastApiAmount={this.state.lastApiAmount}
              isLoading={this.state.isLoading}
              exchange_rates={this.state.exchange_rates}
              amountHeader={this.state.amountHeader}
              lastApiSide={this.state.lastApiSide}
              inputErrorLabel={this.inputErrorLabel}
              error={this.state.error}
              errorLabel={this.state.errorLabel}
            />
            <Footer />
          </Container>
        </ThemeProvider>
      </Sentry.ErrorBoundary>
    );
  }
}
