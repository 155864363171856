import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Paper, Typography } from "@material-ui/core";
import BestRateWidget from "../Widgets/BestRateWidget";
import CalculationResultListEmptyList from "./CalculationResultListEmptyList";
import CalculationResultListSkeleton from "./CalculationResultListSkeleton";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "block",
    margin: "auto",
    "& .MuiList-padding": {
      padding: "0px",
      margin: "0px",
    },
    "& .MuiList-root": {
      width: "100%",
      height: "70vh",
    },
  },
}));

export default function CalculationResultList({
  number_to_currency_string,
  exchange_calculation_result,
  exchange_list,
  scrollRef,
  openModal,
  amount,
  lastApiAmount,
  isLoading,
}) {
  const classes = useStyles();

  const hasValidData = () => {
    return !isLoading && exchange_calculation_result && exchange_list;
  };

  return (
    <Paper className={classes.root}>
      <List
        component="nav"
        aria-label="main mailbox folders"
        className="list__scroll"
        ref={scrollRef}
      >
        {hasValidData() ? (
          (exchange_calculation_result.length === 0 && (
            <CalculationResultListEmptyList />
          )) ||
          (exchange_calculation_result.length > 0 &&
            exchange_calculation_result.map((exchange, index) => {
              const exchangeSearch = exchange_list.find(
                (o) => o.id === exchange.exchange.id
              );
              const name =
                exchangeSearch &&
                exchangeSearch.name +
                  ": " +
                  exchange.steps.find((o) => o.fee_type.fee_type === "INBOUND")
                    .fee_type.transaction_type +
                  ", " +
                  exchange.steps.find((o) => o.fee_type.fee_type === "WITHIN")
                    .fee_type.transaction_type +
                  " fee";
              const number = number_to_currency_string(
                exchange.amount_left,
                exchange.amount_left_currency
              );

              const isBestRate =
                exchange.amount_left ===
                exchange_calculation_result[0].amount_left;
              return (
                <div>
                  <ListItem
                    button
                    onClick={() =>
                      openModal(exchange_calculation_result.indexOf(exchange))
                    }
                    key={exchange.id}
                  >
                    <ListItemText
                      primary={
                        <Typography
                          style={{
                            fontFamily: "Roboto Condensed",
                            color: "#fffff",
                          }}
                        >
                          {name}
                        </Typography>
                      }
                      secondary={
                        <Typography
                          style={{
                            fontFamily: "Eczar",
                            color: "#fffff",
                            letterSpacing: "2.5px",
                          }}
                        >
                          {number}
                        </Typography>
                      }
                    />
                    {isBestRate && <BestRateWidget />}
                    <ListItemIcon style={{ minWidth: "20px" }}>
                      <ChevronRightIcon style={{ fontSize: 22 }} />
                    </ListItemIcon>
                  </ListItem>
                  <Divider />
                </div>
              );
            }))
        ) : (
          <CalculationResultListSkeleton />
        )}
      </List>
    </Paper>
  );
}
