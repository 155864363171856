import { Box, TableBody, TableCell, TableRow } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import React from "react";

export default function CalculationResultTableEmptyList() {
  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={5}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <ErrorOutlineIcon
              style={{ fontSize: "50px", marginBottom: "25px" }}
            />
          </Box>
          <Box
            fontSize="20px"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <p>No matching results found</p>
            <p>Please update values</p>
          </Box>
        </TableCell>
      </TableRow>
    </TableBody>
  );
}
