import React from "react";

export default function ErrorFallback({ error, componentStack, resetError }) {
  return (
    <React.Fragment>
      <div>You have encountered an error :( Please refresh your screen.</div>
      <button
        onClick={() => {
          resetError();
        }}
      >
        Click here to reset!
      </button>
    </React.Fragment>
  );
}
