import "./calculationresulttable.scss";
import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@material-ui/core";
import BestRateWidget from "../Widgets/BestRateWidget";
import CalculationResultTableEmptyList from "./CalculationResultTableEmptyList";
import CalculationResultTableSkeleton from "./CalculationResultTableSkeleton";

const StyledTableRow = withStyles((theme) => ({
  root: {
    // "&:nth-of-type(odd)": {
    //   backgroundColor: theme.palette.action.hover,
    // },
    cursor: "pointer",
    backgroundColor: "#373740",
  },
}))(TableRow);

const StyledTableFooter = withStyles((theme) => ({
  root: {
    padding: "14px",
    color: "#FFFFFF",
    opacity: 0.6,
    cursor: "",
  },
}))(TableFooter);

const useStyles = makeStyles({
  table: {
    width: "100%",
  },
});

export default function CalculationResultTable({
  number_to_currency_string,
  exchange_calculation_result,
  exchange_list,
  isLoading,
  setKey,
  selectedIndex,
  amountHeader,
}) {
  const classes = useStyles();

  const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));

  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
      onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
          color="secondary"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
          color="secondary"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 7));
    setPage(0);
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(7);

  const rowOpacity = () => {
    return isLoading ? 0.4 : 1;
  };

  const columnCount = 5;

  if (isLoading && page !== 0) {
    setPage(0);
  }
  return (
    <div>
      <TableContainer
        component={Paper}
        style={{
          backgroundColor: "#373740",
        }}
      >
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell>Exchange</TableCell>
              <Tooltip
                title="How will the trade be funded"
                placement="top"
                style={{
                  cursor: "help",
                }}
              >
                <TableCell>Deposit Type</TableCell>
              </Tooltip>
              <Tooltip
                title="Is it a maker or taker trade"
                placement="top"
                style={{
                  cursor: "help",
                }}
              >
                <TableCell>Trade Fee</TableCell>
              </Tooltip>
              <Tooltip
                title="How am I withdrawing the asset"
                placement="top"
                style={{
                  cursor: "help",
                }}
              >
                <TableCell>Withdrawal Type</TableCell>
              </Tooltip>
              <Tooltip
                title="Final converted amount after all fees"
                placement="top"
                style={{
                  cursor: "help",
                }}
              >
                <TableCell align="right">
                  <TableSortLabel
                    active={true}
                    direction="desc"
                    disabled
                  ></TableSortLabel>
                  {amountHeader}
                </TableCell>
              </Tooltip>
            </TableRow>
          </TableHead>
          {isLoading && (
            <TableCell
              align="center"
              colSpan={columnCount}
              style={{ padding: "0px", margin: "0px" }}
            >
              <LinearProgress />
            </TableCell>
          )}
          {exchange_calculation_result &&
          exchange_calculation_result.length > 0 ? (
            <TableBody>
              {exchange_list !== undefined &&
                exchange_calculation_result
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow
                      key={page * rowsPerPage + index}
                      hover
                      onClick={() => {
                        setKey(page * rowsPerPage + index);
                      }}
                      selected={selectedIndex === page * rowsPerPage + index}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ opacity: rowOpacity() }}
                      >
                        {exchange_list.find((o) => o.id === row.exchange.id) &&
                          exchange_list.find((o) => o.id === row.exchange.id)
                            .name}
                      </TableCell>
                      <TableCell align="left" style={{ opacity: rowOpacity() }}>
                        {
                          row.steps.find(
                            (o) => o.fee_type.fee_type === "INBOUND"
                          ).fee_type.transaction_type
                        }
                      </TableCell>

                      <TableCell align="left" style={{ opacity: rowOpacity() }}>
                        {
                          row.steps.find(
                            (o) => o.fee_type.fee_type === "WITHIN"
                          ).fee_type.transaction_type
                        }
                      </TableCell>
                      <TableCell align="left" style={{ opacity: rowOpacity() }}>
                        {
                          row.steps.find(
                            (o) => o.fee_type.fee_type === "OUTBOUND"
                          ).fee_type.transaction_type
                        }
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ opacity: rowOpacity() }}
                      >
                        <Box
                          display="flex"
                          justifyContent="flex-end"
                          fontSize="inherit"
                          fontFamily="inherit"
                        >
                          {row.amount_left ===
                            exchange_calculation_result[0].amount_left && (
                            <BestRateWidget />
                          )}
                          {number_to_currency_string(
                            row.amount_left,
                            row.amount_left_currency
                          )}
                        </Box>
                      </TableCell>
                    </StyledTableRow>
                  ))}
            </TableBody>
          ) : isLoading ? (
            <CalculationResultTableSkeleton />
          ) : (
            <CalculationResultTableEmptyList />
          )}
          <StyledTableFooter
            style={{
              borderBottom: "none",
              cursor: "default",
            }}
          >
            <StyledTableRow
              style={{
                borderBottom: "none",
                cursor: "default",
              }}
            >
              <TablePagination
                colSpan={columnCount}
                count={exchange_calculation_result.length}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[]}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </StyledTableRow>
          </StyledTableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}
