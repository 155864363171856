import { Box, Divider, List, ListItem } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";

export default function CalculationResultListSkeleton() {
  return (
    <List className="list__error">
      <ListItem>
        <Box display="flex" flexDirection="column" style={{ width: "100%" }}>
          <Skeleton animation="wave" style={{ width: "50%" }} />
          <Skeleton animation="wave" style={{ width: "25%" }} />
        </Box>
      </ListItem>
      <Divider />
      <ListItem>
        <Box display="flex" flexDirection="column" style={{ width: "100%" }}>
          <Skeleton animation="wave" style={{ width: "50%" }} />
          <Skeleton animation="wave" style={{ width: "25%" }} />
        </Box>
      </ListItem>
      <Divider />
      <ListItem>
        <Box display="flex" flexDirection="column" style={{ width: "100%" }}>
          <Skeleton animation="wave" style={{ width: "50%" }} />
          <Skeleton animation="wave" style={{ width: "25%" }} />
        </Box>
      </ListItem>
      <Divider />
      <ListItem>
        <Box display="flex" flexDirection="column" style={{ width: "100%" }}>
          <Skeleton animation="wave" style={{ width: "50%" }} />
          <Skeleton animation="wave" style={{ width: "25%" }} />
        </Box>
      </ListItem>
      <Divider />
      <ListItem>
        <Box display="flex" flexDirection="column" style={{ width: "100%" }}>
          <Skeleton animation="wave" style={{ width: "50%" }} />
          <Skeleton animation="wave" style={{ width: "25%" }} />
        </Box>
      </ListItem>
      <Divider />
      <ListItem>
        <Box display="flex" flexDirection="column" style={{ width: "100%" }}>
          <Skeleton animation="wave" style={{ width: "50%" }} />
          <Skeleton animation="wave" style={{ width: "25%" }} />
        </Box>
      </ListItem>
      <Divider />
      <ListItem>
        <Box display="flex" flexDirection="column" style={{ width: "100%" }}>
          <Skeleton animation="wave" style={{ width: "50%" }} />
          <Skeleton animation="wave" style={{ width: "25%" }} />
        </Box>
      </ListItem>
      <Divider />
      <ListItem>
        <Box display="flex" flexDirection="column" style={{ width: "100%" }}>
          <Skeleton animation="wave" style={{ width: "50%" }} />
          <Skeleton animation="wave" style={{ width: "25%" }} />
        </Box>
      </ListItem>
      <Divider />
      <ListItem>
        <Box display="flex" flexDirection="column" style={{ width: "100%" }}>
          <Skeleton animation="wave" style={{ width: "50%" }} />
          <Skeleton animation="wave" style={{ width: "25%" }} />
        </Box>
      </ListItem>
      <Divider />
      <ListItem>
        <Box display="flex" flexDirection="column" style={{ width: "100%" }}>
          <Skeleton animation="wave" style={{ width: "50%" }} />
          <Skeleton animation="wave" style={{ width: "25%" }} />
        </Box>
      </ListItem>
    </List>
  );
}
