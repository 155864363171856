import React, { Component } from "react";
import "./widgets.scss";
import { Box, Paper } from "@material-ui/core";

export default class BestRateWidget extends Component {
  render() {
    return (
      <Box display="flex" justifyContent="flex-end">
        <Paper
          elevation={0}
          style={{
            backgroundColor: "#ccffe5",
            padding: "1px 4px 1px 4px",
            margin: "0 6px 0 6px",
          }}
        >
          <Box className="widget-best-rate">Best Rate</Box>
        </Paper>
      </Box>
    );
  }
}
