import React from "react";
import Modal from "react-modal";
import CalculationResultDetails from "../CalculationResultDetails/CalculationResultDetails";
import "./popup.scss";
import CloseIcon from "@material-ui/icons/Close";
import { Fade } from "@material-ui/core";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "20%",
    bottom: "20%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#373740",
    height: "90vh",
    maxHeight: "550px",
    padding: "0px",
  },
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

export default function PopUp({
  number_to_currency_string,
  exchange_calculation_result,
  index,
  symbol,
  closeModal,
  openModal,
  modalIsOpen,
  exchange_list,
  amount,
  lastApiAmount,
  exchange_rates,
  isLoading,
}) {
  return (
    <Fade in={modalIsOpen} timeout={300}>
      <div className="modal">
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Fee and exchange rate details"
        >
          <div className="modal__display">
            <CloseIcon
              onClick={closeModal}
              style={{ marginTop: "8px", marginRight: "8px" }}
            />
          </div>
          <div>
            <CalculationResultDetails
              number_to_currency_string={number_to_currency_string}
              exchange_list={exchange_list}
              exchange_calculation_result={exchange_calculation_result}
              symbol={symbol}
              index={index}
              amount={amount}
              lastApiAmount={lastApiAmount}
              exchange_rates={exchange_rates}
              modalIsOpen={modalIsOpen}
            />
          </div>
        </Modal>
      </div>
    </Fade>
  );
}
