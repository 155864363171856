import { Box, List } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import React from "react";

export default function CalculationResultListEmptyList() {
  return (
    <List className="list__error">
      <Box
        fontSize="20px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        padding="50px"
      >
        <ErrorOutlineIcon style={{ fontSize: "50px", marginBottom: "25px" }} />
        <p>No matching results found</p>
        <p>Please update values</p>
      </Box>
    </List>
  );
}
