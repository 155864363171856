import React from "react";
import "./footer.scss";
import Terms from "../Terms/Terms";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import { Box, Button } from "@material-ui/core";

export default function Footer() {
  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      flex-wrap="wrap"
      className="footer"
    >
      <Terms />
      <PrivacyPolicy />
      <Button href="mailto:cryptolens@tuta.io">Contact us</Button>
      <Button disabled className="footer__paragraph footer__toronto">
        With ♥ from Toronto
      </Button>
    </Box>
  );
}
