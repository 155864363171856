/* eslint-disable react/jsx-no-target-blank */
// import FeePieChart from "../Charts/FeePieChart";
import React from "react";
import "./calculationresultdetails.scss";
import AmountWidget from "../Widgets/AmountWidget";
import Box from "@material-ui/core/Box";
import UpdatedWidget from "../Widgets/UpdatedWidget";
import BestRateWidget from "../Widgets/BestRateWidget";
import { Fade, Paper, Tooltip } from "@material-ui/core";
import CalculationResultDetailsNumberRow from "./CalculationResultDetailsNumberRow";
import CalculationResultDetailsFillsRows from "./CalculationResultDetailsFillsRows";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";

export default function CalculationResultDetails({
  exchange_calculation_result,
  index,
  symbol,
  exchange_list,
  number_to_currency_string,
  lastApiAmount,
  exchange_rates,
  isLoading,
  modalIsOpen,
}) {
  const LinkTypeEnum = {
    HOME: "HOME",
    SIGNUP: "SIGNUP",
    FEE: "FEE",
  };

  const hasValidData = () => {
    return (
      !isLoading &&
      exchange_calculation_result &&
      index >= 0 &&
      symbol &&
      exchange_list &&
      lastApiAmount &&
      exchange_rates &&
      exchange_calculation_result[index]
    );
  };

  const selectedRow = hasValidData() && exchange_calculation_result[index];

  const getExchangeRate = () => {
    if (hasValidData()) {
      let rate = exchange_rates.find(
        (o) => o.exchange.id === selectedRow.exchange.id
      );

      if (rate) {
        return { timestamp: rate.timestamp, now: rate.now };
      } else {
        return { timestamp: 0, now: 600000 };
      }
    }
  };

  const selectedExchange =
    hasValidData() &&
    exchange_list.find((o) => o.id === selectedRow.exchange.id);

  const getLink = (type) => {
    if (selectedExchange === undefined) {
      console.error("cannot find exchange with id: " + selectedRow.exchange.id);
      return "#";
    }

    let links = selectedExchange.links.filter((o) => o.url_type === type);
    if (links === undefined || links.length === 0) {
      console.error(
        "cannot find links for exchange with id: " +
          selectedRow.exchange.id +
          " and type: " +
          type
      );
      return "#";
    }

    return links.map((link) => {
      return link.url;
    });
  };

  if (!hasValidData()) {
    return <span />;
  } else {
    let fee_total =
      Number(
        selectedRow.steps.find((o) => o.fee_type.fee_type === "OUTBOUND")
          .fiat_fee_value
      ) +
      Number(
        selectedRow.steps.find((o) => o.fee_type.fee_type === "INBOUND")
          .fiat_fee_value
      ) +
      Number(
        selectedRow.steps.find((o) => o.fee_type.fee_type === "WITHIN")
          .fiat_fee_value
      );

    const get_within_fee_logic_symbol = () => {
      if (
        selectedRow.steps.find((o) => o.fee_type.fee_type === "WITHIN")
          .fee_value.fee_logic === "PERCENT"
      ) {
        return "%";
      } else if (
        selectedRow.steps.find((o) => o.fee_type.fee_type === "WITHIN")
          .fee_value.fee_logic === "ABSOLUTE_CAD"
      ) {
        return "CAD";
      } else {
        return selectedRow.steps.find((o) => o.fee_type.fee_type === "WITHIN")
          .fee_value.fee_logic;
      }
    };

    const get_header = () => {
      switch (selectedRow.amount_left_currency) {
        case "CAD":
          return "CAD received";
        case "BTC":
          return "BTC bought";
        default:
          console.error(
            "widget header didn't match currency. currency " +
              selectedRow.amount_left_currency
          );
      }
    };

    if (!exchange_list) {
      return <div />;
    } else {
      return (
        <Fade in={!isLoading} timeout={300}>
          <Paper
            elevation={modalIsOpen ? 0 : 1}
            style={
              selectedRow.amount_left ===
                exchange_calculation_result[0].amount_left && !modalIsOpen
                ? {
                    backgroundColor: "#373740",
                    border: "4px solid #ccffe5",
                    padding: "0px",
                  }
                : {
                    backgroundColor: "#373740",
                    border: "0px solid #ccffe5",
                    padding: "4px",
                  }
            }
          >
            <div className="detail_card">
              <p className="details__border">
                <div className="detail_header1">
                  {selectedExchange && selectedExchange.name}
                  {" - "}
                  {
                    selectedRow.steps.find(
                      (o) => o.fee_type.fee_type === "INBOUND"
                    ).fee_type.transaction_type
                  }
                </div>
                <Box style={{ paddingTop: "2px" }}>
                  <UpdatedWidget
                    fromTime={getExchangeRate().timestamp}
                    toTime={getExchangeRate().now}
                  />
                </Box>
              </p>
              <Box
                className="widget_box"
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
              >
                {selectedRow.amount_left ===
                exchange_calculation_result[0].amount_left ? (
                  <span />
                ) : (
                  <AmountWidget
                    header="More expensive than #1 by"
                    amount={
                      exchange_calculation_result[0].fiat_amount_left -
                      selectedRow.fiat_amount_left
                    }
                    approximateAmount
                    currency={selectedRow.fiat_amount_left_currency}
                    style={AmountWidget.StyleEnum.BAD}
                  />
                )}

                <AmountWidget
                  header={get_header()}
                  amount={selectedRow.amount_left}
                  currency={selectedRow.amount_left_currency}
                  style={AmountWidget.StyleEnum.GOOD}
                />
                {selectedRow.amount_left ===
                  exchange_calculation_result[0].amount_left && (
                  <Box style={{ width: "80px" }}>
                    <BestRateWidget />
                  </Box>
                )}
              </Box>
              <div className="detail_header2">Fee Breakdown</div>
              <div className="details__flex details__flex--between">
                <Tooltip
                  title="Only includes deposit fees that the exchange controls and charges."
                  placement="top"
                  style={{
                    cursor: "help",
                  }}
                >
                  <p className="details__flex--margin">
                    {"Deposit Fee - "}
                    {
                      selectedRow.steps.find(
                        (o) => o.fee_type.fee_type === "INBOUND"
                      ).fee_type.transaction_type
                    }

                    <span> *</span>
                  </p>
                </Tooltip>
                <p className="number">
                  {number_to_currency_string(
                    selectedRow.steps.find(
                      (o) => o.fee_type.fee_type === "INBOUND"
                    ).fiat_fee_value
                  )}
                </p>
              </div>
              {selectedRow.steps.find(
                (o) => o.fee_type.fee_type === "WITHIN"
              ) === undefined ? null : (
                <div className="details__flex details__flex--between">
                  <p className="details__flex--margin">
                    {"Trading Fee - "}
                    <span>
                      {number_to_currency_string(
                        selectedRow.steps.find(
                          (o) => o.fee_type.fee_type === "WITHIN"
                        ).fee_value.fee_value,
                        get_within_fee_logic_symbol()
                      )}{" "}
                    </span>

                    {
                      selectedRow.steps.find(
                        (o) => o.fee_type.fee_type === "WITHIN"
                      ).fee_type.transaction_type
                    }
                  </p>
                  <p className="number">
                    {number_to_currency_string(
                      selectedRow.steps.find(
                        (o) => o.fee_type.fee_type === "WITHIN"
                      ).fiat_fee_value
                    )}{" "}
                  </p>
                </div>
              )}
              <div className="details__flex details__flex--between">
                <p className="details__flex--margin">
                  {"Withdrawal Fee - "}
                  {
                    selectedRow.steps.find(
                      (o) => o.fee_type.fee_type === "OUTBOUND"
                    ).fee_type.transaction_type
                  }
                </p>
                <p className="number">
                  {number_to_currency_string(
                    selectedRow.steps.find(
                      (o) => o.fee_type.fee_type === "OUTBOUND"
                    ).fiat_fee_value
                  )}{" "}
                </p>
              </div>
              <div className="details__flex details__flex--between">
                <p>Total</p>
                <p className="number">{number_to_currency_string(fee_total)}</p>
              </div>
              <div className="detail_header2">Exchange Rate</div>
              <CalculationResultDetailsNumberRow
                description="Average Exchange Rate"
                value={
                  number_to_currency_string(selectedRow.exchange_rate) +
                  " " +
                  symbol.substring(0, 3) +
                  "/" +
                  symbol.substring(4, 7)
                }
              />
              <CalculationResultDetailsNumberRow
                description="Slippage / Liquidity Cost"
                value={
                  selectedRow.slippage_percent
                    ? `${selectedRow.slippage_percent}%`
                    : "N/A"
                }
              />

              <div className="detail_header2">Fills</div>

              {selectedRow.fills.length > 0 ? (
                <CalculationResultDetailsFillsRows
                  fills={selectedRow.fills}
                  amount_currency={selectedRow.amount_left_currency}
                  price_currency={"CAD"}
                />
              ) : selectedExchange && selectedExchange.has_order_book ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  style={{ padding: "6px" }}
                >
                  <InfoIcon style={{ marginRight: "8px" }}></InfoIcon>
                  <span>Order Book information available soon.</span>
                </Box>
              ) : (
                <Box
                  display="flex"
                  justifyContent="center"
                  style={{ padding: "6px" }}
                >
                  <WarningIcon
                    style={{ marginRight: "8px", fontSize: "2.5rem" }}
                  ></WarningIcon>
                  <span>
                    This exchange does not provide BTC/CAD order book data.
                    Slippage is possible.
                  </span>
                </Box>
              )}

              <Box
                className="detail_header2"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="flex-end"
              >
                About {selectedExchange && selectedExchange.name}
                <img
                  src={
                    selectedExchange &&
                    process.env.PUBLIC_URL +
                      "/exchange_logo/" +
                      selectedExchange.name +
                      ".png"
                  }
                  onError={(e) => {
                    // @ts-ignore
                    e.target.src = "";
                    // @ts-ignore
                    e.target.alt = "";
                  }}
                  alt={`${selectedRow.exchange.name} logo`}
                  className="details__image"
                />
              </Box>
              <div className="details__flex">
                <div className="exchange_urls_content">
                  {"Links: "}
                  <a
                    rel="noreferrer noopener"
                    href={getLink(LinkTypeEnum.HOME)}
                    className="exchange_urls_links"
                    target="_blank"
                  >
                    Homepage
                  </a>
                  {", "}
                  <a
                    rel="noreferrer noopener"
                    href={getLink(LinkTypeEnum.SIGNUP)}
                    className="exchange_urls_links"
                    target="_blank"
                  >
                    Signup
                  </a>
                  {getLink(LinkTypeEnum.FEE).length === 1 && (
                    <span>
                      {", "}
                      <a
                        key={getLink(LinkTypeEnum.FEE)}
                        href={getLink(LinkTypeEnum.FEE)}
                        rel="noreferrer noopener"
                        className="exchange_urls_links"
                        target="_blank"
                      >
                        Fee Schedule
                      </a>
                    </span>
                  )}
                  {getLink(LinkTypeEnum.FEE).length > 1 && (
                    <span>
                      <br />
                      Fee Schedules{" "}
                    </span>
                  )}

                  {getLink(LinkTypeEnum.FEE).length > 1 &&
                    getLink(LinkTypeEnum.FEE).map((url, index) => {
                      return (
                        <span>
                          <a
                            key={index}
                            href={url}
                            rel="noreferrer noopener"
                            className="exchange_urls_links"
                            target="_blank"
                          >
                            [{index + 1}
                            {"] "}
                          </a>
                        </span>
                      );
                    })}
                </div>
              </div>
            </div>
          </Paper>
        </Fade>
      );
    }
  }
}
