import App from "../../App";
import React from "react";

export default function CalculationResultDetailsFillsRows({
  fills,
  amount_currency,
  price_currency,
}) {
  return (
    <span>
      {fills &&
        fills.map((fill) => (
          <p className="number">{`${App.number_to_currency_string_static(
            fill.amount,
            amount_currency
          )} @ ${App.number_to_currency_string_static(
            fill.price,
            price_currency
          )}`}</p>
        ))}
    </span>
  );
}
