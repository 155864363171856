import React from "react";
import CalculationResultTable from "../CalculationResultTable/CalculationResultTable";
import NumberFormat from "react-number-format";
import PopUp from "../PopUp/PopUp";
import Hidden from "@material-ui/core/Hidden";
import CalculationResultList from "../CalculationResultList/CalculationResultList";
import CalculationResultDetails from "../CalculationResultDetails/CalculationResultDetails";
import "./form.scss";
import {
  Button,
  makeStyles,
  TextField,
  withStyles,
  InputAdornment,
  CircularProgress,
  Grid,
  Paper,
  Box,
  Grow,
} from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

const StyledTextField = withStyles((theme) => ({
  root: {
    width: "130px",
    margin: "8px",
    "& .MuiInput-input": {
      fontSize: "16px",
      padding: "6px 0px 3px 0px",
    },
  },
}))(TextField);

const StyledButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#045d56",
    borderRadius: 3,
    border: 0,
    fontSize: 14,
    margin: "8px",
    "&:focus": {
      backgroundColor: "#045d56",
    },
    "&:after": {
      backgroundColor: "#045d56",
    },
    "&:hover": {
      backgroundColor: "#045d56",
    },
    "&:disabled": {
      backgroundColor: "#787878",
    },
  },
  label: {
    textTransform: "capitalize",
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputBase-root": {
      fontSize: "13px",
      [theme.breakpoints.up("sm")]: {
        width: "100%",
        fontSize: "14px",
      },
      [theme.breakpoints.up("md")]: {
        width: "100%",
        fontSize: "1vw",
      },
    },
  },
  select: {
    color: "#ffff",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "70px",
    },
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
    />
  );
}

export default function Form({
  scrollRef,
  exchange_calculation_result,
  index,
  modalIsOpen,
  setKey,
  onSubmit,
  openModal,
  closeModal,
  number_to_currency_string,
  exchange_list,
  symbol,
  handleChange,
  side,
  amount,
  amountChange,
  lastApiAmount,
  isLoading,
  exchange_rates,
  amountHeader,
  lastApiSide,
  inputErrorLabel,
  error,
  errorLabel,
}) {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid container style={{ flexWrap: "nowrap" }}>
        <Grid
          item
          component={Paper}
          xs={12}
          sm={7}
          style={{
            backgroundColor: "#373740",
            margin: "8px",
            padding: "8px",
          }}
        >
          <form
            className={classes.root}
            id="form"
            noValidate
            autoComplete="off"
          >
            <Box
              display="flex"
              alignItems="flex-end"
              justifyContent="space-evenly"
              flexWrap="wrap"
            >
              <p id="paragraph" className="form-elements">
                I want to
              </p>

              <ToggleButtonGroup value={side} exclusive onChange={handleChange}>
                <ToggleButton
                  style={{ fontSize: "14px", padding: "7px" }}
                  value="BUY"
                  aria-label="BUY"
                >
                  Buy
                </ToggleButton>
                <ToggleButton
                  style={{ fontSize: "14px", padding: "7px" }}
                  value="SELL"
                  aria-label="SELL"
                >
                  Sell
                </ToggleButton>
              </ToggleButtonGroup>
              <StyledTextField
                error={error}
                label={errorLabel}
                onChange={(e) => {
                  amountChange(e);
                  inputErrorLabel(e);
                }}
                type="tel"
                id="filled-basic"
                value={amount}
                onFocus={(event) => event.target.select()}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    onSubmit(event);
                  }
                }}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                  startAdornment: (
                    <InputAdornment
                      disableTypography
                      style={{
                        color: "white",
                        padding: "0px 0px 0px 0px",
                        margin: "6px 6px 6px 6px",
                      }}
                      position="start"
                    >
                      {side === "BUY" ? "$" : "₿"}
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  name: "amount",
                  id: "amount",
                }}
              />
              <Box style={{ width: "160px", height: "32px" }}>
                <Grow
                  in={side === "SELL"}
                  mountOnEnter
                  unmountOnExit
                  style={{ position: "absolute" }}
                >
                  <p id="paragraph" className="form-elements">
                    BTC to CAD
                  </p>
                </Grow>
                <Grow
                  in={side === "BUY"}
                  mountOnEnter
                  unmountOnExit
                  style={{ position: "absolute" }}
                >
                  <p id="paragraph" className="form-elements">
                    CAD worth of BTC
                  </p>
                </Grow>
              </Box>
              <StyledButton
                onClick={onSubmit}
                disabled={isLoading || error}
                variant="contained"
              >
                Calculate
                {isLoading && (
                  <CircularProgress size={24} className="buttonProgress" />
                )}
              </StyledButton>
            </Box>
          </form>
        </Grid>
        <Hidden smDown>
          <Grid
            sm={5}
            style={{
              width: "100%",
              margin: "8px",
            }}
          ></Grid>
        </Hidden>
      </Grid>
      <Grid
        container
        xs={12}
        style={{
          flexWrap: "nowrap",
          justifyContent: "space-between",
        }}
      >
        <Hidden xsDown>
          <Grid
            item
            sm={7}
            style={{
              margin: "8px",
            }}
          >
            <CalculationResultTable
              number_to_currency_string={number_to_currency_string}
              setKey={setKey}
              exchange_list={exchange_list}
              selectedIndex={index}
              exchange_calculation_result={exchange_calculation_result}
              amount={amount}
              lastApiAmount={lastApiAmount}
              isLoading={isLoading}
              amountHeader={amountHeader}
            />
          </Grid>
          <Grid
            item
            xs={5}
            style={{
              margin: "8px",
            }}
          >
            <CalculationResultDetails
              number_to_currency_string={number_to_currency_string}
              exchange_list={exchange_list}
              exchange_calculation_result={exchange_calculation_result}
              symbol={symbol}
              index={index}
              lastApiAmount={lastApiAmount}
              exchange_rates={exchange_rates}
              isLoading={isLoading}
              modalIsOpen={modalIsOpen}
            />
          </Grid>
        </Hidden>
      </Grid>
      <Hidden smUp>
        <Grid
          item
          xs={12}
          sm={12}
          style={{
            margin: "8px",
          }}
        >
          <CalculationResultList
            number_to_currency_string={number_to_currency_string}
            modalIsOpen={modalIsOpen}
            openModal={openModal}
            closeModal={closeModal}
            scrollRef={scrollRef}
            setKey={setKey}
            exchange_list={exchange_list}
            symbol={symbol}
            index={index}
            exchange_calculation_result={exchange_calculation_result}
            amount={amount}
            lastApiAmount={lastApiAmount}
            isLoading={isLoading}
          />
        </Grid>

        <PopUp
          number_to_currency_string={number_to_currency_string}
          exchange_calculation_result={exchange_calculation_result}
          index={index}
          symbol={symbol}
          closeModal={closeModal}
          openModal={openModal}
          modalIsOpen={modalIsOpen}
          exchange_list={exchange_list}
          amount={amount}
          lastApiAmount={lastApiAmount}
          exchange_rates={exchange_rates}
          isLoading={isLoading}
        />
      </Hidden>
    </Grid>
  );
}
