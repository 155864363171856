import { TableBody, TableCell, TableRow } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";

export default function CalculationResultTableSkeleton() {
  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={5}>
          <Skeleton animation="wave" />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={5}>
          <Skeleton animation="wave" />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={5}>
          <Skeleton animation="wave" />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={5}>
          <Skeleton animation="wave" />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={5}>
          <Skeleton animation="wave" />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={5}>
          <Skeleton animation="wave" />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={5}>
          <Skeleton animation="wave" />
        </TableCell>
      </TableRow>
    </TableBody>
  );
}
