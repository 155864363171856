import React, { Component } from "react";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import NumberFormat from "react-number-format";
import AddAlertIcon from "@material-ui/icons/AddAlert";
import Button from "@material-ui/core/Button";
import DoneIcon from "@material-ui/icons/Done";
import Fade from "@material-ui/core/Fade";
import { withStyles } from "@material-ui/core";
import axios from "axios";
import "./alerts.scss";

const cryptolens_url = process.env.REACT_APP_URL;
const alert_price_api = process.env.REACT_APP_ALERT_PRICE_API;

export default class AlertForm extends Component {
  roundUp(num, precision) {
    precision = Math.pow(10, precision);
    return Math.ceil(num * precision) / precision;
  }

  state = {
    alert_amount: this.roundUp(this.props.defaultAmount * 1.05, -2),
    alert_email: null,
    email_empty: true,
    email_error: false,
    amount_error: false,
    saved: false,
    isLoading: false,
    form_valid: false,
  };

  validateEmail(email) {
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  postAlertSubscription() {
    this.setState({ isLoading: true });
    axios
      .post(cryptolens_url + alert_price_api, {
        to_price: this.state.alert_amount,
        from_price: this.props.defaultAmount,
        email: this.state.alert_email,
        symbol: "BTC_CAD",
      })
      .then((res) => {
        this.setState({
          isLoading: false,
          saved: true,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  onSubmit() {
    if (
      this.state.email_empty ||
      this.state.email_error ||
      this.state.amount_error
    ) {
      return;
    }

    this.postAlertSubscription();
  }

  NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
      />
    );
  }

  StyledTextField = withStyles((theme) => ({
    root: {
      margin: "8px",
      "& .MuiOutlinedInput-input": {
        fontSize: "16px",
      },
    },
  }))(TextField);

  StyledButton = withStyles((theme) => ({
    root: {
      backgroundColor: "#045d56",
      borderRadius: 3,
      border: 0,
      fontSize: 14,
      margin: "8px",
      "&:focus": {
        backgroundColor: "#045d56",
      },
      "&:after": {
        backgroundColor: "#045d56",
      },
      "&:hover": {
        backgroundColor: "#045d56",
      },
      "&:disabled": {
        backgroundColor: "#787878",
      },
    },
    label: {
      textTransform: "capitalize",
    },
  }))(Button);

  render() {
    return (
      <Box>
        <Box
          style={{ padding: "24px", display: "flex", flexDirection: "column" }}
        >
          <Box className="alertHeader">
            <AddAlertIcon className="alertHeaderItem" />
            <span className="alertHeaderItem">Price Alert</span>
          </Box>
          <Fade in={this.state.saved}>
            <Box className="savedBox">
              <DoneIcon style={{ marginRight: "8px", fontSize: "3rem" }} />
              <span className="alertDone">Created</span>
            </Box>
          </Fade>
          <Fade in={!this.state.saved}>
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <this.StyledTextField
                error={this.state.amount_error}
                style={{
                  marginTop: "12px",
                  marginBottom: "12px",
                }}
                onChange={(event) => {
                  this.setState({ alert_amount: event.target.value });
                  if (
                    event.target.value == null ||
                    Number(event.target.value) <= 0
                  )
                    this.setState({ amount_error: true });
                  else {
                    this.setState({ amount_error: false });
                  }
                }}
                type="tel"
                id="filled-basic"
                value={this.state.alert_amount}
                onFocus={(event) => event.target.select()}
                placeholder="Price"
                variant="outlined"
                InputProps={{
                  inputComponent: this.NumberFormatCustom,
                  startAdornment: (
                    <InputAdornment
                      disableTypography
                      style={{
                        color: "white",
                        padding: "0px 0px 0px 0px",
                        margin: "6px 6px 6px 6px",
                        fontSize: "16px",
                      }}
                      position="start"
                    >
                      $
                    </InputAdornment>
                  ),
                }}
              />
              <this.StyledTextField
                style={{ marginTop: "12px", marginBottom: "12px" }}
                id="outlined-number"
                type="email"
                error={this.state.email_error}
                value={this.state.alert_email}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    this.onSubmit();
                  }
                }}
                onChange={(event) => {
                  this.setState({
                    email_error: !this.validateEmail(event.target.value),
                    email_empty: event.target.value == null,
                    alert_email: event.target.value,
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                className="alertField"
                placeholder="Email"
              />
            </Box>
          </Fade>
          <this.StyledButton
            variant="contained"
            disabled={
              this.state.amount_error ||
              this.state.email_error ||
              this.state.email_empty
            }
            color="primary"
            size="small"
            style={{
              marginTop: "16px",
              visibility:
                this.state.isLoading || this.state.saved ? "hidden" : "visible",
            }}
            onClick={() => {
              this.onSubmit();
            }}
          >
            Create
          </this.StyledButton>
        </Box>
      </Box>
    );
  }
}
