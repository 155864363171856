import React from "react";
import "./logo.scss";

export default function Logo() {
  return (
    <div className="logo-container">
      <a className="logo" href="/">
        CRYPTOLENS.ca
      </a>
    </div>
  );
}
